import React from 'react';
import BaseComponent from "../../BaseComponent";
import TableComponent, {Column, TablePaginator} from "../../common/TableComponent";
import ScreenSearchComponent from "../common/ScreenSearchComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";

export default class SettingsSiteAreasComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            siteAreasNetworkInFlight : false,
            tableData : [],
            currentPage : 1,
            tableKeyword : null,
            tableTotal : 0,
            sortedColumn : null
        });
    }

    componentDidMount() {
        this.getSiteTableDataFromNetwork();
    }

    searchKeywordDidChange = (keyword) => {
        this.setState({
            tableKeyword : keyword
        });

        this.getSiteTableDataFromNetwork(keyword);
    }

    tableRowWasClicked = (row) => {
        this.moveToSiteAreaEditor(row.id);
    }

    moveToSiteAreaEditor = (id) => {
        let append = "new";
        if (id !== undefined) {
            append = id;
        }
        this.props.history.push("/site-area/" + append);
    }

    tableDidSort = (sorting) => {
        this.setState({
            sortedColumn : sorting
        });

        this.getSiteTableDataFromNetwork(undefined, undefined, sorting);
    }

    tablePageDidChange = (page) => {
        this.setState({
            currentPage : page
        });

        this.getSiteTableDataFromNetwork(undefined, page);
    }

    getSiteTableDataFromNetwork = (keyword, page, sorting) => {
        if (this.state.siteAreasNetworkInFlight) return;

        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (keyword === undefined) {
            keyword = this.state.tableKeyword;
        }

        if (sorting === undefined) {
            sorting = this.state.sortedColumn;
        }

        this.setState({
            siteAreasNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", page);
        if (keyword != null && keyword !== "") {
            formData.append("keyword", keyword);
        }
        if (sorting != null) {
            formData.append("sorting", JSON.stringify(sorting));
        }

        Axios.post(ENDPOINTS.site.getSiteAreaTableData, formData)
            .then((r) => {
                let tableData = [];
                let tableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.data;
                    tableTotal = resp.data.count;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    siteAreasNetworkInFlight : false,
                    tableData : tableData,
                    tableTotal : tableTotal
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    siteAreasNetworkInFlight : false
                });

                this.showError("An unknown error has occurred. Please try again later. [D1101]");
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {

        return (
            <div>
                <div className={"row"}>
                    <div className={"col-6 col-md-4 screen-actions"}>
                        <h3>Site Areas</h3>
                    </div>
                    <div className={"col-6 col-md-4 screen-actions"}>
                        <ScreenSearchComponent
                            callback={(keyword) => { this.searchKeywordDidChange(keyword) }}/>
                    </div>
                    <div className={"col-12 col-md-4 text-right screen-actions"}>
                        <button className={"btn btn-primary"} onClick={() => this.moveToSiteAreaEditor()}>Add Site Area</button>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"alternating clickable"}
                            data={this.state.tableData}
                            onRowClick={(row) => { this.tableRowWasClicked(row);}}
                            onSort={(sort) => { this.tableDidSort(sort) }}
                            sortedColumn={this.state.sortedColumn}>
                            <Column name={"name"} title={"Site Area Name"} sortable={true} />
                            <Column name={"id"} title={""}  className={"text-center"} render={(column, row) => {
                                return (
                                    <span className={"btn btn-primary"}>Edit</span>
                                );
                            }} />
                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.currentPage}
                                totalCount={this.state.tableTotal}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>
                    </div>
                </div>

                <UIBlockerComponent
                    shown={this.state.siteAreasNetworkInFlight} />
            </div>
        )
    }

}