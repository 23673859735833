import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import ScreenSearchComponent from "../common/ScreenSearchComponent";
import TableComponent, {Column, TablePaginator} from "../../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

export default class UserManagementLandingComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            tableData : [],
            sortedColumn : null,
            currentPage : 1,
            tableTotal : 0,
            tableLimit : 20
        })
    }

    componentDidMount() {
        this.getTableDataOverNetwork();
    }

    tableRowWasClicked = (row) => {
        this.props.history.push("/users/" + row.id);
    }

    newButtonWasPressed = () => {
        this.props.history.push("/users/new");
    }

    searchKeywordDidChange = (keyword) => {
        this.setState({
            tableKeyword : keyword
        });

        this.getTableDataOverNetwork(undefined, keyword);
    }

    tablePageDidChange = (page) => {
        this.setState({
            currentPage : page
        });

        this.getTableDataOverNetwork(page);
    }

    tableDidSort = (sort) => {
        this.setState({
            sortedColumn : sort
        });

        this.getTableDataOverNetwork(undefined, undefined, sort);
    }

    getTableDataOverNetwork = (page, keyword, sorting) => {
        if (this.state.tableNetworkInFlight) return;

        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (keyword === undefined) {
            keyword = this.state.tableKeyword;
        }

        if (sorting === undefined) {
            sorting = this.state.sortedColumn;
        }

        this.setState({
            tableNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", page);
        if (keyword != null && keyword !== "") {
            formData.append("keyword", keyword);
        }
        if (sorting != null) {
            formData.append("sorting", JSON.stringify(sorting));
        }

        Axios.post(ENDPOINTS.user.getUsersAdmin, formData)
            .then((r) => {
                let tableData = [];
                let tableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.data;
                    tableTotal = resp.data.count;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    tableNetworkInFlight : false,
                    tableData,
                    tableTotal
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {
        return (
            <div className={"container user-management-landing-component"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <ScreenTitleComponent
                            title={"User Management"}
                            showBack={false} />
                    </div>
                    <div className={"col-6 col-md-4 screen-actions"}>
                        <ScreenSearchComponent
                            callback={(keyword) => { this.searchKeywordDidChange(keyword) }}/>
                    </div>
                    <div className={"col-12 col-md-2 text-right screen-actions"}>
                        <button className={"btn btn-primary"} onClick={this.newButtonWasPressed}>Add User</button>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"alternating clickable"}
                            data={this.state.tableData}
                            onRowClick={(row) => { this.tableRowWasClicked(row);}}
                            onSort={(sort) => { this.tableDidSort(sort) }}
                            sortedColumn={this.state.sortedColumn}>
                            <Column name={"givenName"} title={"User Name"} sortable={true} render={(column, row) => {
                                return row.givenName + " " + row.familyName;
                            }} />
                            <Column name={"employeeId"} title={"Employee ID"} className={"text-center"} sortable={true} />
                            <Column name={"siteCount"} title={"# Sites Assigned"} className={"text-center"} sortable={true} />
                            <Column name={"userTypeName"} title={"User Type"} className={"text-center"} sortable={true} />
                            <Column name={"id"} title={""}  className={"text-center"} render={(column, row) => {
                                return (
                                    <span className={"btn btn-primary"}>View</span>
                                );
                            }} />
                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.currentPage}
                                totalCount={this.state.tableTotal}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}