import React from 'react';
import BaseComponent from "../../BaseComponent";
import DatePicker from "react-datepicker";
import TableComponent, {Column, TablePaginator} from "../TableComponent";
import {Chronos} from "../../../data/Chronos";
import {API, ENDPOINTS} from "../../../network/API";
import Axios from "axios";
import {Toast} from "../../dashboard/common/ToastManagerComponent";

import "./EstateReportTableComponent.css";

export default class EstateReportTableComponent  extends BaseComponent {

    TABLE_MODE_DEFAULT = "-1";

    constructor(props, context) {
        super(props, context);

        let startDate = Chronos.now().add(-1, Chronos.WEEKS).getDate();
        let endDate = Chronos.now().getDate();

        this.initState({
            tableMode : this.TABLE_MODE_DEFAULT,
            tableModes : [],
            currentPage : 1,
            tableTotal : 0,
            tableLimit : 20,
            tableData : [],
            tableKeyword : undefined,
            auditTypes : null,
            startDate,
            endDate
        });
    }

    componentDidMount() {
        this.fetchEstateReportFromNetwork();

        if (this.state.auditTypes === null) {
            this.fetchAuditTypesFromNetwork();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.hasOwnProperty("tableKeyword")) {
            if (prevProps.tableKeyword !== this.props.tableKeyword) {
                this.setState({
                    tableKeyword : this.props.tableKeyword
                });

                this.fetchEstateReportFromNetwork(1, this.props.tableKeyword);
            }
        }

        if (prevState.tableMode !== undefined) {
            if (prevState.tableMode !== this.state.tableMode) {
                this.setState({
                    currentPage : 1,
                    tableData : [],
                    tableTotal : 0
                }, () => {
                    this.fetchEstateReportFromNetwork();
                });
            }
        }
    }

    tableRowWasClicked = (row) => {
        this.props.history.push("/sites/" + row.id + "/stats");
    }

    tablePageDidChange = (page) => {
        this.setState({
            currentPage : page
        });

        this.fetchEstateReportFromNetwork(page);
    }

    onDateSelected = (type, date) => {
        if (type === "start") {
            this.setState({
                currentPage : 1,
                startDate : date
            });
        } else {
            this.setState({
                currentPage : 1,
                endDate : date
            });
        }

        setTimeout(() => {
            this.fetchEstateReportFromNetwork(1);
        }, 10);
    }

    constructAuditTypeOptions = () => {
        let tableModes = [
            { id : this.TABLE_MODE_DEFAULT, label : "Store & Contractor" }
        ];

        if (this.state.auditTypes !== null) {
            for (let i = 0; i < this.state.auditTypes.length; i++) {
                let auditType = this.state.auditTypes[i];

                tableModes.push({
                    id : auditType.id,
                    label : auditType.name
                });
            }
        }

        this.setState({
            tableModes
        });
    }

    fetchEstateReportFromNetwork = (page, keyword) => {
        if (this.state.estateReportNetworkInFlight) return;

        this.setState({
            estateReportNetworkInFlight : true
        });

        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (keyword === undefined) {
            keyword = this.state.tableKeyword;
        }

        let formData = new FormData();
        formData.append("page", page);
        formData.append("startDate", Chronos.with(this.state.startDate).seconds());
        formData.append("endDate", Chronos.with(this.state.endDate).seconds());

        if (this.state.tableMode !== this.TABLE_MODE_DEFAULT) {
            formData.append("auditTypeId", this.state.tableMode);
        }

        if (keyword !== undefined && keyword != null && keyword !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.site.getEstateReportSummaryTableData, formData)
            .then((r) => {
                let tableData = [];
                let tableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.data;
                    tableTotal = resp.data.count;
                } else {
                    Toast.show("Error", resp.error.desc, Toast.TYPE_ERROR);
                }

                this.setState({
                    estateReportNetworkInFlight : false,
                    tableData,
                    tableTotal
                });
            })
            .catch((e) => {
                Toast.show("Error", "An unknown error has occurred. Please reload the page.", Toast.TYPE_ERROR);
            });
    }

    fetchAuditTypesFromNetwork = () => {
        if (this.state.auditTypeNetworkInFlight) return;

        this.setState({
            auditTypeNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.audit.getAuditTypes)
            .then((r) => {
                let resp = API.parse(r);

                let auditTypes = null;

                if (resp.success) {
                    if (resp.data.auditTypes !== undefined) {
                        auditTypes = resp.data.auditTypes;
                    }
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    auditTypeNetworkInFlight : false,
                    auditTypes
                }, () => {
                    this.constructAuditTypeOptions();
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    render() {
        let scoreColumns = [];
        let answerColumn = [];

        console.log("Table mode: " + this.state.tableMode + " :: ?: " + ((this.state.tableMode === this.TABLE_MODE_DEFAULT) ? "MATCH" : "NO MATCH"));

        if (this.state.tableMode === this.TABLE_MODE_DEFAULT) {
            scoreColumns.push(
                <Column name={"storePercent"} className={"text-center"} title={"Score"} render={(column, row) => {
                    let label = "0%";
                    if (column != null) {
                        label = column + "%"
                    }
                    return (
                        <span>{label}</span>
                    );
                }} />
            );
            scoreColumns.push(
                <Column name={"contractorPercent"} className={"text-center"} title={"Contractor Score"} render={(column, row) => {
                    let label = "0%";
                    if (column != null) {
                        label = column + "%";
                    }
                    return (
                        <span>{label}</span>
                    );
                }} />
            )
        } else {
            scoreColumns.push(
                <Column name={"scorePercent"} className={"text-center"} title={"Score"} render={(column, row) => {
                    let label = "0%";
                    if (column != null) {
                        label = column + "%"
                    }
                    return (
                        <span>{label}</span>
                    );
                }} />
            );

            answerColumn = (
                <Column name={"lastSubmissionAnswerCount"} title={"# Answers"} className={"text-center"} />
            );
        }

        let emptyMessage = "No data to display";
        if (this.state.estateReportNetworkInFlight) {
            emptyMessage = "Loading, please wait...";
        }

        return (
            <div className={"estate-report-table-component"}>
                <div className={"row table-filter"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>Display Data for:</label>
                                    </div>

                                    <div className={"col-12 col-md-4 col-lg-3"}>
                                        <label>Start Date</label>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            className={"form-control"}
                                            onChange={(date) => this.onDateSelected("start", date)}
                                            dateFormat={"dd/MM/yyyy"} />
                                    </div>

                                    <div className={"col-12 col-md-4 col-lg-3"}>
                                        <label>End Date</label>
                                        <DatePicker
                                            selected={this.state.endDate}
                                            className={"form-control"}
                                            onChange={(date) => this.onDateSelected("end", date)}
                                            dateFormat={"dd/MM/yyyy"} />
                                    </div>

                                    <div className={"col-12 col-md-4 col-lg-3"}>
                                        <label>Show data for:</label>
                                        <select className={"form-control"} value={this.state.tableMode} name={"tableMode"} onChange={this.handleChange}>
                                            {
                                                this.state.tableModes.map((mode) => (
                                                    <option value={mode.id}>{mode.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"alternating clickable"}
                            data={this.state.tableData}
                            emptyMessage={emptyMessage}
                            onRowClick={(row) => { this.tableRowWasClicked(row);}}>
                            <Column name={"number"} title={"Asset Number"} />
                            <Column name={"name"} title={"Asset Name"} />
                            {scoreColumns}
                            <Column name={"lastSubmissionDate"} className={"text-center"} title={"Latest Audit Date"} render={(column, row) => {
                                if (column != null) {
                                    return Chronos.withTimestampSeconds(column).format("dd/MM/yyyy HH:mm")
                                }
                                return "None";
                            }} />
                            {answerColumn}
                            <Column name={"id"} title={""}  className={"text-center"} render={(column, row) => {
                                return (
                                    <span className={"btn btn-primary"}>Details</span>
                                );
                            }} />
                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.currentPage}
                                totalCount={this.state.tableTotal}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}