import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";
import ScreenSearchComponent from "../common/ScreenSearchComponent";

import "./UserManagementEditorComponent.css";
import {Toast} from "../common/ToastManagerComponent";
import UserSelectionDialogComponent from "./UserSelectionDialogComponent";

export default class UserManagementEditorComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let isNewUser = true;
        let userId = this.getUriProperty("id", null);
        if (userId != null && userId !== "new") {
            isNewUser = false;
        }

        this.initState({
            userNetworkInFlight : false,
            configNetworkInFlight : false,
            siteNetworkInFlight : false,
            passwordNetworkInFlight : false,
            isNewUser,
            assignedSites : [],
            userTypes : [],
            userUserTypeId : -1,
            contractors : [],
            regions : [],
            userRegionId : -1,
            sites : [],
            userPassword : "",
            userPasswordConfirm : "",
            userSelectionOpen : false,
        })
    }

    componentDidMount() {
        let userId = this.getUriProperty("id", null);
        if (userId != null && userId !== "new") {
            this.getUserFromNetwork();
        }
        
        this.getUserEditorDataFromNetwork();
        this.getSitesFromNetwork();
    }

    populateUserIntoState = (user) => {
        if (user != null) {
            this.setState({
                userEmailAddress : user.emailAddress,
                userGivenName : user.givenName,
                userFamilyName : user.familyName,
                userEmployeeId : user.employeeId,
                userUserTypeId : user.userTypeId,
                userContractorId : user.contractorId,
                userRegionId : user.regionId,
                assignedSites : user.sites,
                isNewUser : false,
                userAuditEmailNotification : user.auditEmailNotification == 1,
                userScoreNotifications : user.scoreNotifications == 1
            });
        }
    }

    populateStateIntoUser = () => {
        let user = this.state.user;
        if (user == null) {
            user = {};
        }

        user.emailAddress = this.state.userEmailAddress;
        user.givenName = this.state.userGivenName;
        user.familyName = this.state.userFamilyName;
        user.employeeId = this.state.userEmployeeId;
        user.userTypeId = this.state.userUserTypeId;
        user.contractorId = this.state.userContractorId;
        user.regionId = this.state.regionId;
        user.auditEmailNotification = this.state.userAuditEmailNotification;
        user.scoreNotification = this.state.userScoreNotifications;

        return user;
    }

    removeSite = (site) => {
        let assignedSites = this.state.assignedSites;

        for (let i = 0; i < assignedSites.length; i++) {
            if (assignedSites[i].id == site.id) {
                assignedSites.splice(i, 1);
                break;
            }
        }

        this.setState({
            assignedSites
        });
    }

    addSite = (site) => {
        let assignedSites = this.state.assignedSites;

        let hasSite = false;
        for (let i = 0; i < assignedSites.length; i++) {
            if (assignedSites[i].id == site.id) {
                hasSite = true;
                break;
            }
        }

        if (!hasSite) {
            assignedSites.push(site);
        }

        this.setState({
            assignedSites
        });
    }

    promptSiteCopyConfirmation = () => {
        this.props.showModal("Confirmation", "When you copy Sites from another User any currently selected Sites will be overwritten.", [
            {
                label : "Understood, continue",
                className : "success",
                click : () => {
                    this.summonUserSelectionDialog();
                    this.props.hideModal();
                }
            },
            {
                label : "Cancel",
                className : "danger",
                click : () => {
                    this.props.hideModal();
                }
            }
        ])
    }

    summonUserSelectionDialog = () => {
        this.setState({
            userSelectionOpen : true
        });
    }

    userSelectionWasSelected = (user) => {
        if (user !== undefined) {
            this.copySitesOverNetwork(user);
        }

        this.setState({
            userSelectionOpen : false
        });
    }

    getUserFromNetwork = () => {
        let userId = this.getUriProperty("id", null);
        if (userId == null || userId === "new") return;
        if (this.state.userNetworkInFlight) return;

        this.setState({
            userNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("id", userId);

        Axios.post(ENDPOINTS.user.getUser, formData)
            .then((r) => {
                let user = null;

                let resp = API.parse(r);
                if (resp.success) {
                    user = resp.data.user;
                    this.populateUserIntoState(user);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    userNetworkInFlight : false,
                    user
                });
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Please reload the page. [D1001]");
                this.setState({
                    userNetworkInFlight : false
                });
            });
    }

    getUserEditorDataFromNetwork = () => {
        if (this.state.configNetworkInFlight) return;

        this.setState({
            configNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.user.getUserEditorData)
            .then((r) => {
                let userTypes = [];
                let contractors = [];
                let regions = [];
                let userUserTypeId = this.state.userUserTypeId;
                let userRegionId = this.state.userRegionId;

                let resp = API.parse(r);
                if (resp.success) {
                    userTypes = resp.data.userTypes;
                    contractors = resp.data.contractors;
                    regions = resp.data.regions;

                    if (userTypes.length > 0 && userUserTypeId === -1) {
                        userUserTypeId = userTypes[0].id;
                    }

                    if (regions.length > 0 && userRegionId === -1) {
                        userRegionId = regions[0].id;
                    }
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    configNetworkInFlight : false,
                    userTypes,
                    userUserTypeId,
                    contractors,
                    regions,
                    userRegionId
                });
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Please try again later.");
                this.setState({
                    configNetworkInFlight: false
                });
            });
    }

    getSitesFromNetwork = (keyword) => {
        if (this.state.sitesNetworkInFlight) return;

        this.setState({
            sitesNetworkInFlight : true
        });

        let formData = new FormData();
        if (keyword !== undefined && keyword !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.site.searchSites, formData)
            .then((r) => {
                let sites = [];

                let resp = API.parse(r);
                if (resp.success) {
                    sites = resp.data.sites;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    sitesNetworkInFlight : false,
                    sites
                });
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Please reload the page.");
                this.setState({
                    sitesNetworkInFlight : false
                });
            });
    }

    submitUserOverNetwork = (user) => {
        if (this.state.userNetworkInFlight) return;

        let validationResult = this.validateCreateFormData(this.state, [
            { key : "userEmailAddress", type : "string", label : "Email Address", postName : "emailAddress" },
            { key : "userGivenName", type : "string", label : "Given Name", postName : "givenName" },
            { key : "userFamilyName", type : "string", label : "Family Name", postName : "familyName" },
            { key : "userUserTypeId", type : "integer", label : "User Type", postName : "userTypeId" },
            { key : "userEmployeeId", type : "integer", label : "Employee ID", postName : "employeeId" },
            { key : "userContractorId", type : "integer", label : "Contractor", postName : "contractorId", optional : true },
            { key : "userRegionId", type : "integer", label : "User Region", postName : "regionId", optional : true },
            { key : "userAuditEmailNotification", type : "boolean", label : "Email Notifications", postName : "auditEmailNotification", optional : true },
            { key : "userScoreNotifications", type : "boolean", label : "Score Notifications", postName: "scoreNotifications", optional: true }
        ]);

        if (!validationResult.success) {
            this.showError(validationResult.error);
            return;
        }

        this.setState({
            userNetworkInFlight : true
        });

        let assignedSiteIds = [];
        this.state.assignedSites.forEach((site) => {
            assignedSiteIds.push(site.id);
        })

        let formData = validationResult.formData;
        if (this.state.user != null) {
            formData.append("id", this.state.user.id);
        }
        formData.append("assignedSiteIds", JSON.stringify(assignedSiteIds));

        Axios.post(ENDPOINTS.user.submitUser, formData)
            .then((r) => {
                let user = this.state.user;

                let resp = API.parse(r);
                if (resp.success) {
                    user = resp.data.user;
                    this.populateUserIntoState(user);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    userNetworkInFlight : false,
                    user
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please reload the page. [D1701]");
                this.setState({
                    userNetworkInFlight : false
                });
            });
    }

    submitUserPasswordOverNetwork = () => {
        if (this.state.passwordNetworkInFlight) return;
        if (!this.isPasswordValid()) return;

        this.setState({
            passwordNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("userId", this.state.user.id);
        formData.append("password", this.state.userPassword);

        Axios.post(ENDPOINTS.user.setUserPassword, formData)
            .then((r) => {
                let state = {
                    passwordNetworkInFlight : false
                };

                let resp = API.parse(r);
                if (resp.success) {
                    state.userPassword = "";
                    state.userPasswordConfirm = "";

                    Toast.show("Success", "Successfully set User's password", Toast.TYPE_SUCCESS);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState(state);
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later. [D1710]");
            });
    }

    copySitesOverNetwork = (targetUser) => {
        if (this.state.siteCopyOverNetwork) return;

        this.setState({
            siteCopyOverNetwork : true
        });

        let formData = new FormData();
        formData.append("targetUserId", targetUser.id);
        formData.append("destinationUserId", this.state.user.id);

        Axios.post(ENDPOINTS.user.copyUserSites, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateUserIntoState(resp.data.user);
                    Toast.show("Success", "Successfully copied Sites into User", Toast.TYPE_SUCCESS);
                } else {
                    Toast.show("Error", API.formatError(resp), Toast.TYPE_ERROR);
                }

                this.setState({
                    siteCopyOverNetwork : false
                });
            })
            .catch((e) => {
                console.log(e);
                Toast.show("Error", "Could not copy Sites, an error occurred.", Toast.TYPE_ERROR);
                this.setState({
                    siteCopyOverNetwork : false
                });
            })
    }

    promptToAddAllSites = () => {
        this.props.showModal("Add all Sites?", "Are you sure you want to add all Sites?", [
            {
                label : "Yes",
                className : "success",
                click : () => {
                    this.props.hideModal();

                    this.addAllSites();

                    Toast.show("Sites added", "Successfully added Sites, please remember to save the User record", Toast.TYPE_INFO);
                }
            },
            {
                label : "No",
                click : () => {
                    this.props.hideModal();
                }
            }
        ])
    }

    promptToRemoveUser = () => {
        this.props.showModal("Confirm Deletion", "Are you sure you want to remove this User? This action cannot be undone.", [
            {
                label : "Delete",
                className : "danger",
                click : () => {
                    this.props.hideModal();
                    this.deleteUserOverNetwork();
                }
            },
            {
                label : "Cancel",
                click : () => {
                    this.props.hideModal();
                }
            }
        ]);
    }

    deleteUserOverNetwork = () => {
        this.setState({
            userNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("userId", this.state.user.id);

        Axios.post(ENDPOINTS.user.deleteUser, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.props.history.push("/users");

                    Toast.show("User Deleted", "Successfully deleted User", Toast.TYPE_SUCCESS);
                } else {
                    this.showError(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please reload the page. [D1730]");
            });
    }

    addAllSites = () => {
        let assignedSites = this.state.assignedSites;

        this.state.sites.forEach((site) => {
            let alreadyAdded = false;
            for (let i = 0; i < assignedSites.length; i++) {
                if (assignedSites[i].id === site.id) {
                    alreadyAdded = true;
                }
            }

            if (!alreadyAdded) {
                assignedSites.push(site);
            }
        });

        this.setState({
            assignedSites
        });
    }

    isPasswordValid = () => {
        if (this.state.userPassword !== "" && this.state.userPasswordConfirm !== "") {
            return this.state.userPassword === this.state.userPasswordConfirm;
        }
        return false;
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {
        let siteItems = [];
        if (this.state.assignedSites.length > 0) {
            siteItems = this.state.assignedSites.map((site) => {
                return (
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        {site.name}
                        <span className="badge badge-primary badge-pill badge-danger clickable" onClick={() => this.removeSite(site)}>Remove</span>
                    </li>
                )
            });
        } else {
            siteItems = (
                <li className="list-group-item d-flex justify-content-between align-items-center text-center">
                    No Sites to Display
                </li>
            )
        }

        let passwordButtonExtraClass = "";
        if (!this.isPasswordValid()) {
            passwordButtonExtraClass = " disabled";
        }

        let passwordField = [];
        if (this.state.user !== undefined && this.state.user !== null) {
            passwordField = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <h3>User Password</h3>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <p>Set the user's password.</p>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <label>Password</label>
                                <input type={"password"} className={"form-control"} name={"userPassword"} value={this.state.userPassword} placeholder={"New Password"} onChange={this.handleChange} />
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <label>Confirm Password</label>
                                <input type={"password"} className={"form-control"} name={"userPasswordConfirm"} value={this.state.userPasswordConfirm} placeholder={"Confirm New Password"} onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className={"row button-space"}>
                            <div className={"col-12"}>
                                <button className={"btn btn-success" + passwordButtonExtraClass} onClick={this.submitUserPasswordOverNetwork}>Set Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let copyButton = [];
        let deleteButton = [];
        if (!this.state.isNewUser) {
            copyButton = (<button className={"btn btn-primary h3-match-margin"} onClick={this.promptSiteCopyConfirmation}>Copy from another User</button>);
            deleteButton = (<button className={"btn btn-danger"} onClick={this.promptToRemoveUser}>Delete User</button>);
        }

        return (
            <div className={"container form-inputs user-management-editor-component"}>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <ScreenTitleComponent
                            title={(this.state.isNewUser) ? "Create User" : "Edit User"}
                            {...this.props} />
                    </div>
                    <div className={"col-6 text-right screen-actions"}>
                        <button className={"btn btn-success"} onClick={this.submitUserOverNetwork}>Save Changes</button>
                        &nbsp;
                        {deleteButton}
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>User Details</h3>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <label>Forename</label>
                        <input type={"text"} className={"form-control"} name={"userGivenName"} value={this.state.userGivenName} onChange={this.handleChange} />
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <label>Surname</label>
                        <input type={"text"} className={"form-control"} name={"userFamilyName"} value={this.state.userFamilyName} onChange={this.handleChange} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <label>Email Address</label>
                        <input type={"email"} className={"form-control"} name={"userEmailAddress"} value={this.state.userEmailAddress} onChange={this.handleChange} />
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <label>Employee ID</label>
                        <input type={"text"} className={"form-control"} name={"userEmployeeId"} value={this.state.userEmployeeId} onChange={this.handleChange} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <label>User Type</label>
                        <select className={"form-control"} name={"userUserTypeId"} value={this.state.userUserTypeId} onChange={this.handleChange}>
                            {
                                this.state.userTypes.map((userType) => (
                                    <option value={userType.id}>{userType.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <label>Contractor</label>
                        <select className={"form-control"} name={"userContractorId"} value={this.state.userContractorId} onChange={this.handleChange}>
                            {
                                this.state.contractors.map((contractor) => (
                                    <option value={contractor.id}>{contractor.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <label>{process.env.REACT_APP_BRAND_NAME} Region</label>
                        <select className={"form-control"} name={"userRegionId"} value={this.state.userRegionId} onChange={this.handleChange}>
                            {
                                this.state.regions.map((region) => (
                                    <option value={region.id}>{region.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <label>Email Notifications</label>
                        <p>
                            <label><input type={"checkbox"} name={"userAuditEmailNotification"} checked={this.state.userAuditEmailNotification} onChange={this.handleChange} /> Allow User to trigger Email Notifications</label>
                            <label><input type={"checkbox"} name={"userScoreNotifications"} checked={this.state.userScoreNotifications} onChange={this.handleChange} /> Receive &lt;70% Email Notifications</label>
                        </p>
                    </div>
                </div>

                {passwordField}

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"row"}>
                            <div className={"col-12 col-md-8"}>
                                <h3>Assigned Sites</h3>
                            </div>
                            <div className={"col-12 col-md-4 text-right"}>
                                <span className={"btn btn-secondary h3-match-margin"} onClick={this.promptToAddAllSites}>Add all Sites</span>
                                &nbsp;
                                {copyButton}
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <label>Assigned Sites</label>
                                <ul className="list-group selection-list">
                                    {siteItems}
                                </ul>
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <label>Add Sites</label>

                                <ScreenSearchComponent
                                    placeholder={"Search for Sites"}
                                    callback={this.getSitesFromNetwork} />

                                <ul className="list-group selection-list">
                                    {
                                        this.state.sites.map((site) => {
                                            let badgeClass = " badge-primary";
                                            let badgeLabel = "Add";

                                            let isAlreadyAdded = false;
                                            this.state.assignedSites.forEach((assigned) => {
                                                if (site.id === assigned.id) {
                                                    isAlreadyAdded = true;
                                                    return false;
                                                }
                                            });

                                            if (isAlreadyAdded) {
                                                badgeClass = " badge-success";
                                                badgeLabel = "Added";
                                            }

                                            return (
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    {site.name}
                                                    <span className={"badge badge-primary badge-pill clickable" + badgeClass} onClick={() => this.addSite(site)}>{badgeLabel}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <UserSelectionDialogComponent
                    open={this.state.userSelectionOpen}
                    callback={this.userSelectionWasSelected} />

                <UIBlockerComponent
                    shown={this.state.userNetworkInFlight || this.state.configNetworkInFlight || this.state.siteCopyOverNetwork} />
            </div>
        )
    }

}