import React from 'react';
import BaseComponent from "../../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

import "./SiteConfigurationEditorDetailsComponent.css";
import {Toast} from "../common/ToastManagerComponent";
import {DataManager} from "../../../data/DataManager";

import closeCross from "../../../assets/europackaging/close-cross.svg";

export default class SiteConfigurationEditorDetailsComponent extends BaseComponent {

    DATA_TYPE_NUMERIC = 1;
    DATA_TYPE_BOOLEAN = 2;

    // User types that are restricted to view
    readOnlyUserTypes = [
        API.userTypes.STORE_CHECK,
        API.userTypes.CONTRACTOR,
        API.userTypes.AUDIT_TEAM,
        API.userTypes.COVID19
    ]

    TOILETS_STAFF = 1;
    TOILETS_CUSTOMER = 2;

    constructor(props, context) {
        super(props, context);

        let user = DataManager.getUser();

        this.initState({
            siteConfigNetworkInFlight : false,
            regions : [],
            contractors : [],
            siteFormats : [],
            user,
            machines : [],
            siteMachines : [],
            deletedMachineIds : []
        });
    }

    componentDidMount() {
        if (this.props.site !== undefined) {
            this.populateSite(this.props.site);
        }

        this.getSiteConfigFromNetwork();
        this.getMachinesFromNetwork();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.site !== undefined) {
            let updateSite = true;
            if (prevProps.site !== undefined) {
                if (this.props.site === prevProps.site) {
                    updateSite = false;
                }
            }

            if (updateSite) {
                this.populateSite(this.props.site);
            }
        }
    }

    populateSite = (site) => {
        if (site !== undefined) {
            this.setState({
                siteName : site.name,
                siteNumber : site.number,
                siteAddress1 : site.addressLine1,
                siteAddress2 : site.addressLine2,
                siteAddressCity : site.addressCity,
                siteAddressCounty : site.addressCounty,
                siteAddressPostcode : site.addressPostcode,
                siteAddressCountry : site.addressCountry,
                siteRegionId : site.regionId,
                siteContactNumber : site.contactNumber,
                siteContractorId : site.contractorId,
                siteFormatId : site.siteFormatId,
                siteFootages : site.footages,
                siteToilets : site.toilets,
                siteContactEmailAddress : site.contactEmailAddress,
                siteMachines : site.machines
            });
        }
    }

    compileSite = () => {
        let outSite = this.props.site;
        if (outSite === undefined) {
            outSite = {};
        }

        outSite.name = this.state.siteName;
        outSite.number = this.state.siteNumber;
        outSite.addressLine1 = this.state.siteAddress1;
        outSite.addressLine2 = this.state.siteAddress2;
        outSite.addressCity = this.state.siteAddressCity;
        outSite.addressCounty = this.state.siteAddressCounty;
        outSite.addressPostcode = this.state.siteAddressPostcode;
        outSite.addressCountry = this.state.siteAddressCountry;
        outSite.regionId = this.state.siteRegionId;
        outSite.contactNumber = this.state.siteContactNumber;
        outSite.contractorId = this.state.siteContractorId;
        outSite.siteFormatId = this.state.siteFormatId;
        outSite.footages = this.state.siteFootages;
        outSite.toilets = this.state.siteToilets;
        outSite.contactEmailAddress = this.state.siteContactEmailAddress;
        outSite.siteMachines = this.state.siteMachines;
        outSite.deletedSiteMachineIds = this.state.deletedMachineIds;

        return outSite;
    }

    formWasSaved = () => {
        let site = this.compileSite();

        if (this.props.changeCallback !== undefined) {
            this.props.changeCallback(site);
        }
    }

    getSiteConfigFromNetwork = () => {
        if (this.state.siteConfigNetworkInFlight) return;

        this.setState({
            siteConfigNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.site.getSiteConfigData)
            .then((r) => {
                let regions = [];
                let contractors = [];
                let siteFormats = [];

                let resp = API.parse(r);
                if (resp.success) {
                    regions = resp.data.regions;
                    contractors = resp.data.contractors;
                    siteFormats = resp.data.siteFormats;
                } else {
                    Toast.show("Uh oh", "Could not load Site Config Data.", Toast.TYPE_ERROR);
                }

                this.setState({
                    siteConfigNetworkInFlight : false,
                    regions,
                    contractors,
                    siteFormats
                });
            })
            .catch((e) => {

            });
    }

    setSiteFootageValue = (categoryId, siteFootageId, value) => {
        let footages = this.state.siteFootages;
        for (let i = 0; i < footages.length; i++) {
            if (footages[i].id === categoryId) {
                for (let x = 0; x < footages[i].footages.length; x++) {
                    if (footages[i].footages[x].id == siteFootageId) {
                        footages[i].footages[x].value = value;
                    }
                }
            }
        }

        this.setState({
            siteFootages : footages
        });
    }

    getSiteToiletValue = (type) => {
        if (this.state.siteToilets !== undefined) {
            if (type === this.TOILETS_STAFF) {
                return this.state.siteToilets.staff;
            } else if (type === this.TOILETS_CUSTOMER) {
                return this.state.siteToilets.customer;
            }
        }

        return 0;
    }

    setSiteToiletValue = (type, value) => {
        let siteToilets = this.state.siteToilets;
        console.log(siteToilets);
        if (siteToilets === undefined || siteToilets === null) {
            siteToilets = {};
        }

        if (type === this.TOILETS_STAFF) {
            siteToilets.staff = value;
        } else if (type === this.TOILETS_CUSTOMER) {
            siteToilets.customer = value;
        }

        this.setState({
            siteToilets
        });
    }

    getMachinesFromNetwork = () => {
        Axios.get(ENDPOINTS.site.getMachines)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.setState({
                        machines : resp.data.machines
                    })
                } else {
                    Toast.show("Error", "Could not load Machines", Toast.TYPE_ERROR);
                }
            })
            .catch((e) => {
                console.log(e);
                Toast.show("Error", "Unknown error when trying to fetch Machines", Toast.TYPE_ERROR);
            });
    }

    createMachine = () => {
        let machines = this.state.siteMachines;

        let machineId = null;
        if (this.state.machines != null && this.state.machines.length > 0) {
            machineId = this.state.machines[0].id;
        }

        machines.push({
            machineId,
            serialNumber : null
        });
        this.setState({
            siteMachines : machines
        });
    }

    updateMachine = (machine, machineId, serialNumber) => {
        let siteMachines = this.state.siteMachines;

        let index = -1;
        for (let i = 0; i < siteMachines.length; i++) {
            if (siteMachines[i] === machine) {
                index = i;
                break;
            }
        }

        if (machineId !== undefined) {
            machine.machineId = machineId;
        }

        if (serialNumber !== undefined) {
            machine.serialNumber = serialNumber;
        }

        siteMachines[index] = machine;

        this.setState({
            siteMachines
        });
    }

    removeMachine = (machine) => {
        let deletedMachineIds = this.state.deletedMachineIds;
        let siteMachines = this.state.siteMachines;
        for (let i = 0; i < siteMachines.length; i++) {
            if (siteMachines[i] === machine) {
                siteMachines.splice(i, 1);
                if (machine.id !== undefined) {
                    deletedMachineIds.push(machine.id);
                }
                break;
            }
        }

        this.setState({
            siteMachines
        });
    }

    render() {
        let readOnlyState = false;
        this.readOnlyUserTypes.forEach((userTypeId) => {
            if (parseInt(this.state.user.userTypeId) === userTypeId) {
                readOnlyState = true;
                return false;
            }
        });

        let footagesElements = [];
        if (this.state.siteFootages !== undefined) {
            this.state.siteFootages.forEach((category) => {
                let footageItems = [];

                let shouldTotal = parseInt(category.shouldTotal) === 1;

                let total = 0;

                category.footages.forEach((footage) => {
                    if (shouldTotal) {
                        if (parseInt(footage.dataType) === 1) {
                            total += parseInt(footage.value);
                        }
                    }

                    let inputElem = (<input type={"number"} className={"form-control"} name={"footage_" + footage.id} value={footage.value} placeholder={footage.name} onChange={(e) => this.setSiteFootageValue(category.id, footage.id, e.target.value)} readOnly={readOnlyState} />);
                    if (parseInt(footage.dataType) === this.DATA_TYPE_BOOLEAN) {
                        inputElem = (
                            <select value={footage.value} className={"form-control"} onChange={(e) => this.setSiteFootageValue(category.id, footage.id, e.target.value)} disabled={readOnlyState}>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                            </select>
                        )
                    }

                    footageItems.push(
                        <div className={"col-12 col-md-6 col-lg-3"}>
                            <label>{footage.name}</label>
                            {inputElem}
                        </div>
                    )
                });

                if (shouldTotal) {
                    footageItems.push(
                        <div className={"col-12 col-md-6 col-lg-3"}>
                            <label>Total</label>
                            <input type={"number"} className={"form-control"} value={total} readOnly={true} />
                        </div>
                    )
                }

                footagesElements.push(
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <h3>{category.name}</h3>
                        </div>

                        {footageItems}
                    </div>
                );
            })
        }

        let submitArea = [];
        if (!readOnlyState) {
            submitArea = (
                <div className={"col-12 submit-area"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <button className={"btn btn-success"} onClick={this.formWasSaved}>Save Changes</button>
                        </div>
                    </div>
                </div>
            )
        }

        let machines = this.state.machines.map((machine) => (
            <option value={machine.id}>{machine.name}</option>
        ))

        let machineAddButton = [];
        if (!readOnlyState) {
            machineAddButton = (<button className={"btn btn-primary header-button"} onClick={this.createMachine}>Add Machine</button>);
        }

        return (
            <div className={"row site-configuration-editor-details-component form-inputs"}>
                <div className={"col-12 col-lg-8"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <h3>Basic Details</h3>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <label>Asset Name</label>
                            <input type={"text"} className={"form-control"} name={"siteName"} value={this.state.siteName} onChange={this.handleChange} placeholder={"Asset Name"} readOnly={readOnlyState} />
                        </div>

                        <div className={"col-12 col-md-6"}>
                            <label>Asset ID/Store Number</label>
                            <input type={"text"} className={"form-control"} name={"siteNumber"} value={this.state.siteNumber} onChange={this.handleChange} placeholder={"Store Number"} readOnly={readOnlyState} />
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <h3>Address</h3>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>Address Line 1</label>
                                    <input type={"text"} className={"form-control"} name={"siteAddress1"} value={this.state.siteAddress1} onChange={this.handleChange} placeholder={"Address Line 1"} readOnly={readOnlyState} />
                                </div>

                                <div className={"col-12"}>
                                    <label>Address Line 2</label>
                                    <input type={"text"} className={"form-control"} name={"siteAddress2"} value={this.state.siteAddress2} onChange={this.handleChange} placeholder={"Address Line 2"} readOnly={readOnlyState} />
                                </div>

                                <div className={"col-12"}>
                                    <label>Town/City</label>
                                    <input type={"text"} className={"form-control"} name={"siteAddressCity"} value={this.state.siteAddressCity} onChange={this.handleChange} placeholder={"Town/City"} readOnly={readOnlyState} />
                                </div>

                                <div className={"col-12"}>
                                    <label>County</label>
                                    <input type={"text"} className={"form-control"} name={"siteAddressCounty"} value={this.state.siteAddressCounty} onChange={this.handleChange} placeholder={"County"} readOnly={readOnlyState} />
                                </div>

                                <div className={"col-12"}>
                                    <label>Post Code</label>
                                    <input type={"text"} className={"form-control"} name={"siteAddressPostcode"} value={this.state.siteAddressPostcode} onChange={this.handleChange} placeholder={"Post Code"} readOnly={readOnlyState} />
                                </div>

                                <div className={"col-12"}>
                                    <label>Country</label>
                                    <input type={"text"} className={"form-control"} name={"siteAddressCountry"} value={this.state.siteAddressCountry} onChange={this.handleChange} placeholder={"Country"} readOnly={readOnlyState} />
                                </div>

                                <div className={"col-12"}>
                                    <label>{process.env.REACT_APP_BRAND_NAME} Region</label>
                                    <select className={"form-control"} value={this.state.siteRegionId} name={"siteRegionId"} onChange={this.handleChange} disabled={readOnlyState}>
                                        {
                                            this.state.regions.map((region) =>
                                                <option value={region.id}>{region.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={"col-12 col-md-6"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>Contact Number</label>
                                    <input type={"text"} className={"form-control"} name={"siteContactNumber"} value={this.state.siteContactNumber} onChange={this.handleChange} placeholder={"Contact Number"} readOnly={readOnlyState} />
                                </div>

                                <div className={"col-12"}>
                                    <label>Contact Email Address</label>
                                    <input type={"text"} className={"form-control"} name={"siteContactEmailAddress"} value={this.state.siteContactEmailAddress} onChange={this.handleChange} placeholder={"Contact Email Address"} readOnly={readOnlyState} />
                                </div>

                                <div className={"col-12"}>
                                    <label>Contractor</label>
                                    <select className={"form-control"} value={this.state.siteContractorId} name={"siteContractorId"} onChange={this.handleChange} disabled={readOnlyState}>
                                        <option value={null}>None</option>
                                        {
                                            this.state.contractors.map((contractor) =>
                                                <option value={contractor.id}>{contractor.name}</option>
                                            )
                                        }
                                    </select>
                                </div>

                                <div className={"col-12"}>
                                    <label>Site Format</label>
                                    <select className={"form-control"} value={this.state.siteFormatId} name={"siteFormatId"} onChange={this.handleChange} disabled={readOnlyState}>
                                        <option value={null}>N/A</option>
                                        {
                                            this.state.siteFormats.map((format) =>
                                                <option value={format.id}>{format.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"col-12"}>
                    {footagesElements}

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <h3>Staff Toilets</h3>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12 col-md-6 col-lg-3"}>
                            <label>Staff</label>
                            <input type={"number"} className={"form-control"} name={"footageToiletsStaff"} value={this.getSiteToiletValue(this.TOILETS_STAFF)} placeholder={"Staff Toilets"} onChange={(e) => this.setSiteToiletValue(this.TOILETS_STAFF, e.target.value)} readOnly={readOnlyState} />
                        </div>

                        <div className={"col-12 col-md-6 col-lg-3"}>
                            <label>Customer</label>
                            <input type={"number"} className={"form-control"} name={"footageToiletsCustomer"} value={this.getSiteToiletValue(this.TOILETS_CUSTOMER)} placeholder={"Customer Toilets"} onChange={(e) => this.setSiteToiletValue(this.TOILETS_CUSTOMER, e.target.value)} readOnly={readOnlyState} />
                        </div>
                    </div>

                    <div className={"row submit-area"}>
                        <div className={"col-12 col-md-6"}>
                            <div className={"card machine-card"}>
                                <div className={"card-header"}>
                                    <div className={"row"}>
                                        <div className={"col-8"}>
                                            <h5 className="card-title">Site Machines</h5>
                                        </div>

                                        <div className={"col-4 text-right"}>
                                            {machineAddButton}
                                        </div>
                                    </div>
                                </div>
                                <div className={"card-body"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            {
                                                this.state.siteMachines.map((machine) => {
                                                    let deleteElem = [];
                                                    if (!readOnlyState) {
                                                        deleteElem.push(<label className={"block-label"}>&nbsp;</label>);
                                                        deleteElem.push(<span className={"remove-icon"} style={{backgroundImage : "url(" + closeCross + ")"}} onClick={() => this.removeMachine(machine)} />)
                                                    }

                                                    return (
                                                        <div className={"row"}>
                                                            <div className={"col-7"}>
                                                                <label>Machine</label>
                                                                <select className={"form-control"} value={machine.machineId} onChange={(e) => this.updateMachine(machine, e.target.value)} disabled={readOnlyState}>
                                                                    {machines}
                                                                </select>
                                                            </div>

                                                            <div className={"col-4"}>
                                                                <label>Serial Number</label>
                                                                <input type={"text"} className={"form-control"} value={machine.serialNumber} onChange={(e) => this.updateMachine(machine, undefined, e.target.value)} readOnly={readOnlyState} />
                                                            </div>

                                                            <div className={"col-1 text-center"}>
                                                                {deleteElem}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {submitArea}
            </div>
        )
    }
}