import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";

import "./SettingsHomeScreenEditorComponent.css";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../common/ToastManagerComponent";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";

export default class SettingsHomeScreenEditorComponent extends BaseComponent {

    configItems = [
        {
            title : "Home Screen Labels",
            items : [
                {
                    id : "home_alerts_label",
                    defaultValue : "Alerts"
                },
                {
                    id : "home_cleaning_checks_label",
                    defaultValue : (process.env.REACT_APP_VERBIAGE_CLEANING_CHECK !== undefined) ? process.env.REACT_APP_VERBIAGE_CLEANING_CHECK : "Cleaning Checks"
                },
                {
                    id : "home_inspection_audit_label",
                    defaultValue : "Site Inspections"
                },
                {
                    id : "home_cleaning_spec_label",
                    defaultValue : "Cleaning Specifications"
                }
            ]
        },
        {
            title : "Login Screen Labels",
            items : [
                {
                    id : "login_temporary_login_label",
                    defaultValue : "Temporary User Type"
                }
            ]
        }
    ]

    constructor(props, context) {
        super(props, context);

        this.initState({
            appConfigNetworkInFlight : false
        });
    }

    componentDidMount() {
        this.fetchAppConfigFromNetwork();
    }

    fetchAppConfigFromNetwork = () => {
        if (this.state.appConfigNetworkInFlight) return;

        this.setState({
            appConfigNetworkInFlight : true
        });

        let keys = [];
        this.configItems.forEach((section) => {
            section.items.forEach((item) => {
                keys.push(item.id);
            });
        });

        let formData = new FormData();
        formData.append("keys", JSON.stringify(keys));

        Axios.post(ENDPOINTS.config.getConfig)
            .then((r) => {
                let state = {};

                let resp = API.parse(r);
                if (resp.success) {
                    state = resp.data;
                } else {
                    Toast.show("Error", API.formatError(resp), Toast.TYPE_ERROR);
                }

                state.appConfigNetworkInFlight = false;

                this.setState(state);
            })
            .catch((e) => {
                Toast.show("Error", "Could not load App Config. Please reload page", Toast.TYPE_ERROR);
            });
    }

    setAppConfigOverNetwork = () => {
        if (this.state.appConfigNetworkInFlight) return;

        this.setState({
            appConfigNetworkInFlight : true
        });

        let formData = new FormData();

        let data = {};
        this.configItems.forEach((section) => {
            section.items.forEach((item) => {
                data[item.id] = this.getOrDefault(this.state, item.id, "");
            });
        });

        formData.append("data", JSON.stringify(data));

        Axios.post(ENDPOINTS.config.setConfig, formData)
            .then((r) => {
                let state = {};

                let resp = API.parse(r);
                if (resp.success) {
                    state = resp.data;

                    Toast.show("Success", "Successfully updated Home Screen", Toast.TYPE_SUCCESS);
                } else {
                    this.showError(API.formatError(resp));
                }

                state.appConfigNetworkInFlight = false;

                this.setState(state);
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Please reload the page.");
            });
    }

    showError = (message) => {
        if (this.props.showModal !== undefined) {
            this.props.showModal("Error", message);
        }
    }

    render() {

        let content = [];
        this.configItems.forEach((section) => {
            content.push(<div className={"row"}>
                <div className={"col-12"}>
                    <h4>{section.title}</h4>
                </div>

                {
                    section.items.map((item) => (
                        <div className={"col-12 col-md-4 col-lg-3"}>
                            <label>{item.defaultValue}</label>
                            <input type={"text"} className={"form-control"} name={item.id} value={this.state[item.id]} placeholder={item.defaultValue} onChange={this.handleChange} />
                        </div>
                    ))
                }
            </div>)
        });

        return (
            <div className={"container form-inputs settings-home-screen-editor-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent
                            title={"Customise"}
                            {...this.props} />
                    </div>
                </div>

                {content}

                <div className={"row submit-area"}>
                    <div className={"col-12"}>
                        <button className={"btn btn-success"} onClick={this.setAppConfigOverNetwork}>Save Changes</button>
                    </div>
                </div>

                <UIBlockerComponent
                    shown={this.state.appConfigNetworkInFlight} />
            </div>
        )

    }

}