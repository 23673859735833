import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import ScreenSearchComponent from "../common/ScreenSearchComponent";
import {TablePaginator} from "../../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

import "./SiteConfiguratorLandingComponent.css";
import {Toast} from "../common/ToastManagerComponent";

export default class SiteConfiguratorLandingComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            currentPage : 1,
            tableKeyword : "",
            sites : [],
            siteAreas : [],
            tableTotal : 0,
            tableLimit : 20
        });
    }

    componentDidMount() {
        this.getSiteTableDataFromNetwork();
        this.getConfigurationFromNetwork();
    }

    moveToSiteDetail = (site) => {
        let suffix = "new";
        if (site !== undefined) {
            suffix = site.id;
        }

        this.props.history.push("/sites/" + suffix);
    }

    findSite = (siteId) => {
        for (let i = 0; i < this.state.sites.length; i++) {
            if (this.state.sites[i].id == siteId) {
                return this.state.sites[i];
            }
        }
        return null;
    }

    checkSiteArea = (siteId, siteAreaId) => {
        let site = this.findSite(siteId);
        if (siteId != null) {
            let hasSiteArea = false;

            for (let i = 0; i < site.siteAreas.length; i++) {
                if (site.siteAreas[i] == siteAreaId) {
                    hasSiteArea = true;
                    site.siteAreas.splice(i, 1);
                    break;
                }
            }

            if (!hasSiteArea) {
                site.siteAreas.push(siteAreaId);
            }

            this.setState({
                sites : this.state.sites
            });
        }
    }

    tablePageDidChange = (page) => {
        this.setState({
            currentPage : page
        });

        this.getSiteTableDataFromNetwork(page);
    }

    tableFilterDidChange = (keyword) => {
        this.setState({
            currentPage : 1,
            tableKeyword : keyword
        });

        this.getSiteTableDataFromNetwork(1, keyword);
    }

    getSiteTableDataFromNetwork = (page, keyword) => {
        if (this.state.siteNetworkInFlight) return;

        this.setState({
            siteNetworkInFlight : true
        });

        if (page === undefined) {
            page = this.state.currentPage;
        }

        if (keyword === undefined) {
            keyword = this.state.tableKeyword;
        }

        let formData = new FormData();
        formData.append("page", page);
        if (keyword !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.site.getAdminSites, formData)
            .then((r) => {
                let sites = [];
                let tableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    sites = resp.data.data;
                    tableTotal = resp.data.count;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    siteNetworkInFlight : false,
                    sites,
                    tableTotal
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later.");
            })
    }

    getConfigurationFromNetwork = () => {
        if (this.state.configNetworkInFlight) return;

        this.setState({
            configNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.audit.getAuditQuestionConfigurationData)
            .then((r) => {
                let siteAreas = [];
                let userTypes = [];

                let resp = API.parse(r);
                if (resp.success) {
                    siteAreas = resp.data.siteAreas;
                    userTypes = resp.data.userTypes;
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    configNetworkInFlight : true,
                    siteAreas,
                    userTypes
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    configNetworkInFlight : false
                });
            });
    }

    submitSiteConfigurationOverNetwork = () => {
        if (this.state.siteNetworkInFlight) return;

        this.setState({
            siteNetworkInFlight : false
        });

        let data = [];
        this.state.sites.forEach((site) => {
            data.push({
                siteId : site.id,
                siteAreaIds : site.siteAreas
            });
        })

        let formData = new FormData();
        formData.append("data", JSON.stringify(data));

        Axios.post(ENDPOINTS.site.submitAdminSiteConfiguration, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    Toast.show("Success", "Successfully updated Site Configuration", Toast.TYPE_SUCCESS);
                } else {
                    this.showError(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later.");
            });
    }

    promptToAssignSiteAreas = (siteArea) => {
        this.props.showModal("Assign Site Area", "Which action would you like to perform?", [
            {
                label : "Assign " + siteArea.name + " to all Sites",
                className : "success",
                click : () => {
                    this.promptBatchConfirmation(siteArea, API.batchType.ASSIGN);
                }
            },
            {
                label : "Remove " + siteArea.name + " from all Sites",
                className : "danger",
                click : () => {
                    this.promptBatchConfirmation(siteArea, API.batchType.REMOVE);
                }
            },
            {
                label : "Cancel",
                click : () => {
                    this.props.hideModal();
                }
            }
        ])
    }

    promptBatchConfirmation = (siteArea, batchType) => {
        this.setState({
            batchAssignSiteArea : siteArea,
            batchAssignType : batchType
        });

        let action = "assign";
        if (batchType === API.batchType.REMOVE) {
            action = "remove";
        }

        this.props.showModal("Confirmation", "Are you sure you wish to " + action + " Site Area: \"" + siteArea.name + "\" to all sites? This action cannot be undone.", [
            {
                label : "Yes, continue",
                className : "success",
                click : () => {
                    this.batchAssignSiteAreasOverNetwork(siteArea, batchType);
                    this.props.hideModal();
                }
            },
            {
                label : "No, cancel",
                className : "danger",
                click : () => {
                    this.props.hideModal();
                }
            }
        ]);
    }

    batchAssignSiteAreasOverNetwork = (siteArea, batchType) => {
        if (this.state.batchAssignNetworkInFlight) {
            return;
        }

        this.setState({
            batchAssignNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("siteAreaId", siteArea.id);
        formData.append("assignType", batchType);

        if (this.state.tableKeyword !== "") {
            formData.append("keyword", this.state.tableKeyword);
        }

        Axios.post(ENDPOINTS.site.batchAssignSiteArea, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    Toast.show("Success", "Site Area has been assigned successfully", Toast.TYPE_SUCCESS);

                    this.getSiteTableDataFromNetwork();
                } else {
                    Toast.show("Error", resp.error.desc, Toast.TYPE_ERROR);
                }

                this.setState({
                    batchAssignNetworkInFlight : false
                });
            })
            .catch((e) => {
                Toast.show("Error", "An unknown error has occurred while attempting to assign Site Area to Sites.", Toast.TYPE_ERROR);

                this.setState({
                    batchAssignNetworkInFlight : false
                });
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    formatSiteDisplayName = (site) => {
        let fields = ["number", "name", "addressPostcode", "regionName", "siteFormatName"];

        let out = "";
        fields.forEach((field) => {
            if (site[field] !== undefined && site[field] !== "") {
                if (out !== "") {
                    out += ", ";
                }
                out += site[field];
            }
        });

        return out;
    }

    render() {

        return (
            <div className={"container-flex site-configurator-landing-component"}>
                <div className={"row"}>
                    <div className={"col-4"}>
                        <ScreenTitleComponent
                            title={"Site Configurator"}
                            showBack={false}
                            {...this.props} />
                    </div>
                    <div className={"col-4 screen-actions"}>
                        <ScreenSearchComponent
                            callback={this.tableFilterDidChange} />
                    </div>
                    <div className={"col-4 screen-actions text-right"}>
                        <button className={"btn btn-primary"} onClick={() => this.moveToSiteDetail()}>Add Site</button>
                        &nbsp;
                        <button className={"btn btn-success"} onClick={this.submitSiteConfigurationOverNetwork}>Save Changes</button>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>

                        <div className={"segmented-table-container"}>
                            <div className={"equal-width"}>
                                <table className={"alternating"}>
                                    <thead>
                                    <tr>
                                        <th><span className={"text"}>Site</span></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.sites.map((site) => (
                                            <tr className={"clickable"} onClick={() => this.moveToSiteDetail(site)}><td><span className={"text"}>{this.formatSiteDisplayName(site)}</span></td></tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div className={"centre-width"}>
                                <table className={"alternating"}>
                                    <thead>
                                    <tr>
                                        {
                                            this.state.siteAreas.map((siteArea) => (
                                                <th className={"text-center site-area-header"} onClick={() => this.promptToAssignSiteAreas(siteArea)}><span className={"text"}>{siteArea.name}</span></th>
                                            ))
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.sites.map((site) => (
                                            <tr>
                                                {
                                                    this.state.siteAreas.map((siteArea) => {
                                                        let checked = false;

                                                        for (let i = 0; i < site.siteAreas.length; i++) {
                                                            if (site.siteAreas[i] == siteArea.id) {
                                                                checked = true;
                                                                break;
                                                            }
                                                        }

                                                        return (
                                                            <td className={"text-center"}><span className={"text"}><input type={"checkbox"} checked={checked} onClick={() => this.checkSiteArea(site.id, siteArea.id)} /></span></td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div>
                                <table className={"alternating"}>
                                    <thead>
                                    <tr>
                                        <th><span className={"text"}>&nbsp;</span></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.sites.map((site) => (
                                            <tr>
                                                <td className={"text-center"}>
                                                    <span className={"text"}><span className={"btn btn-primary"} onClick={() => this.moveToSiteDetail(site)}>Edit</span></span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.currentPage}
                                totalCount={this.state.tableTotal}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>

                    </div>
                </div>
            </div>
        )

    }
}